import { defineStore } from 'pinia'

export const useAppStore = defineStore('AppStore', {
  state: () => ({
    theme: 'white' // white | blue | gray | neutral
  }),
  actions: {
    setTheme(theme: string) {
      this.theme = theme
    }
  }
})
